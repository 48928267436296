import React from "react";
import "./Header.css";

const Header = () => (
  <header className="App-header">
    <img src="/foundation-logo.png" alt="logo" />
    <div className="logoUnderline" />
  </header>
);

export default Header;
