export const styles = {
  title: {
    flexGrow: 1,
    display: { xs: "flex" },
    top: "-2rem",
    left: 0,
    textShadow: "0 2px 4px black",
    position: "absolute",
    textTransform: "uppercase",
    fontWeight: "fontWeightLight",
  },
};

export default styles;
