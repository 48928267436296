import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    components: {
      Menu: {
        styleOverrides: {
          root: {
            background: "red",
          },
        },
      },
    },
    navlinks: {
      flex: 1,
      marginLeft: theme?.spacing(5),
      display: "flex",
      // backgroundColor: "pink",
    },
    logo: {
      flexGrow: "1",
      cursor: "pointer",
    },
    link: {
      flex: 1,
      flexDirection: "row",
      textDecoration: "none",
      color: theme.palette.text.primaryLight,
      fontSize: "20px",
      textAlign: "center",
      // backgroundColor: "orange",
      // marginLeft: theme?.spacing(20),
      "&:hover": {
        color: theme.palette.primary.light,
        // borderBottom: "1px solid white",
      },
    },
    logoLink: {
      textDecoration: "none",
    },
    menuLink: {
      minWidth: 100,
      marginLeft: 0,
      color: theme.palette.text.primaryDark,
      "&:hover": {
        borderBottom: `1px solid ${theme.palette.text.primaryDark}`,
      },
    },
    menulinksWrapper: {
      opacity: 1,
      background: "red",
    },
  })
);
