/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Section from "components/Section";

import styles from "./styles";

const Main = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Container maxWidth="xl">
      <Section {...props} title="Mission Statement:">
        <Typography variant="h5" sx={{ fontWeight: "fontWeightLight" }}>
          <p css={styles.p}>
            Educational Freedom Foundation is supportive of entrepreneurial
            educational ambassadors and families seeking alternative learning
            environments to secure and promote the academic success of children
            and to conserve the family values they wish to endure. All programs
            that EFF supports exist to serve teachers and families participating
            in alternative educational environments outside of traditional
            public, private, and charter schooling.
          </p>
          <p>
            Through three tiers of investments, we support everyday
            entrepreneurs implementing unconventional, out-of-system learning
            within their communities, provide additional resources to grow the
            programs with the most promise, and ultimately, transform and save
            the future of education.
          </p>
        </Typography>
      </Section>
      <Section {...props} title="Grant Programs:">
        <Box sx={{ fontWeight: "fontWeightLight" }}>
          <Typography variant="h5">
            Three purposes for funding is to provide:
          </Typography>
          <ol>
            <li>
              Grants for start-up expenses for establishing an alternative
              educational environment.
            </li>
            <li>
              Grants for necessary expenses used to support a community in an
              established alternative educational environment.
            </li>
            <li>
              Grants for tuition assistance for families seeking alternative
              educational options.
            </li>
          </ol>
          <p>
            Alternative education is defined as any school or learning
            environment outside of the system of public schools or private
            schools funded by State or Federal dollars.
          </p>
        </Box>
      </Section>
      <div>
        {/* {
          <Button variant="contained" onClick={handleOpen}>
            Open modal
          </Button>
        } */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styles.modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
            <Button variant="contained" onClick={handleClose} sx={{ mt: 2 }}>
              Close modal
            </Button>
          </Box>
        </Modal>
      </div>
    </Container>
  );
};

export default Main;
