import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Copyright = (props) => (
  <Typography variant="body2" color="#cae3fe" align="center" {...props}>
    {"Copyright © "}
    <Link color="#cae3fe" href="#">
      Educational Freedom Foundation
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

export default Copyright;
