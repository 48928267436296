import React from "react";
import Typography from "@mui/material/Typography";

import styles from "./styles";

const AppText = ({ children }) => (
  <Typography variant="h5" sx={{ fontWeight: "fontWeightLight" }}>
    <p style={styles.p}>{children}</p>
  </Typography>
);

export default AppText;
