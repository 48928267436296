export const styles = {
  link: {
    color: "#fff788",
    fontSize: 48,
  },
  donate: {
    fontSize: 36,
    paddingLeft: 200,
    height: 200,
    backgroundImage: "url(/donation.png)",
    backgroundSize: "contain",
    backgroundPosition: "left",
    backgroundRepeat: "no-repeat",
  },
  img: {
    width: "100%",
    height: "100%",
  },
};

export default styles;
