/** @jsxImportSource @emotion/react */
import Container from "@mui/material/Container";

import Section from "components/Section";
import AppText from "components/AppText";

const About = () => {
  return (
    <Container maxWidth="xl">
      <Section title="About Information:">
        <AppText>
          Educational Freedom Foundation is supportive of entrepreneurial
          educational ambassadors and families seeking alternative learning
          environments to secure and promote the academic success of children
          and to conserve the family values they wish to impart. All programs
          that EFF supports exist to serve teachers and families participating
          in alternative educational environments outside of traditional public,
          private, and charter schooling.
        </AppText>
      </Section>
      <Section title="Objectives:">
        <AppText>
          <ol>
            <li>
              Grants for start-up expenses for establishing an alternative
              educational environment.
            </li>
            <li>
              Grants for necessary expenses used to support a community in an
              established alternative educational environment.
            </li>
            <li>
              Scholarships for tuition assistance for families seeking
              alternative educational options.
            </li>
            <li>Consulting and aiding in future educational needs.</li>
            <li>Serving micro-schools in need of a Fiscal Sponsor.</li>
          </ol>
        </AppText>
      </Section>
    </Container>
  );
};

export default About;
