import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "./styles";

const boxProps = {
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundColor: "primary.dark",
  color: "text.primaryLight",
  fontSize: 24,
  marginTop: "8rem",
  borderRadius: 8,
  background: "rgba(0,0,0,0.2)",
  transition: "all 0.4s",
  "&:hover": {
    textShadow: "2px 2px 2px black",
  },
};

const Section = ({ title, children, ...props }) => {
  const matches = useMediaQuery("(min-width:1000px)");
  return (
    <Box {...props} sx={{ ...boxProps, padding: matches ? "4rem" : "2rem" }}>
      <Typography color="primary.light" variant="h5" noWrap sx={styles.title}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default Section;
