/** @jsxImportSource @emotion/react */
import React from "react";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import Section from "components/Section";
import Form from "components/Form";

// import styles from "./styles";

const Forms = (props) => {
  return (
    <Container maxWidth="xl">
      <Section {...props} title="Scholarship Application:">
        <Box sx={{ fontWeight: "fontWeightLight" }}>
          <Typography variant="h5">
            Three purposes for funding is to provide:
          </Typography>
          <ol>
            <li>
              Grants for start-up expenses for establishing an alternative
              educational environment.
            </li>
            <li>
              Grants for necessary expenses used to support a community in an
              established alternative educational environment.
            </li>
            <li>
              Grants for tuition assistance for families seeking alternative
              educational options.
            </li>
          </ol>
          <p>
            Alternative education is defined as any school or learning
            environment outside of the system of public schools or private
            schools funded by State or Federal dollars.
          </p>
        </Box>
      </Section>
      <Form />
      <UseFormControl />
    </Container>
  );
};

const MyFormHelperText = () => {
  const { focused } = useFormControl() || {};

  const helperText = React.useMemo(() => {
    if (focused) {
      return "This field is being focused";
    }

    return "Helper text";
  }, [focused]);

  return <FormHelperText>{helperText}</FormHelperText>;
};

export const UseFormControl = () => (
  <form noValidate autoComplete="off">
    <FormControl sx={{ width: "25ch" }}>
      <OutlinedInput placeholder="Please enter text" />
      <MyFormHelperText />
    </FormControl>
  </form>
);

export default Forms;
