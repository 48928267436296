/** @jsxImportSource @emotion/react */
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Section from "components/Section";

import styles from "./styles";

const Schools = (props) => {
  return (
    <Container maxWidth="xl">
      <Section {...props} title="Schools Information:">
        <Typography variant="h5" sx={{ fontWeight: "fontWeightLight" }}>
          <p style={styles.p}>Coming soon ...</p>
        </Typography>
      </Section>
    </Container>
  );
};

export default Schools;
