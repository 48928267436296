import { css } from "@emotion/react";

export const styles = {
  p: css`
    margin: 0;
  `,
  modalStyle: (theme) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background: ${theme.palette.background.paper};
    border: 2px solid #000;
    box-shadow: 24px;
    padding: 4rem;
  `,
};

export default styles;
