export const pages = [
  { url: "/about", name: "About Us", active: true },
  { url: "/board", name: "Our Board", active: true },
  { url: "/forms", name: "Apply", active: false },
  { url: "/donate", name: "Donate", active: true },
  { url: "/sponsors", name: "Sponsors", active: false },
  { url: "/schools", name: "Schools", active: true },
];

export const settings = [
  "Profile",
  "Account",
  "Dashboard",
  "Logout",
  "Forms",
  "Donate",
  "Sponsors",
];
