import React from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { orange, grey } from "@mui/material/colors";

import Header from "components/Header";
import Navbar from "components/Navbar";
import Copyright from "components/Copyright";

import "./App.css";
import Signup from "components/Signup";
import Main from "components/Main";
import About from "components/About";
import Board from "components/Board";
import Forms from "components/Forms";
import Donate from "components/Donate";
import Sponsors from "components/Sponsors";
import Schools from "components/Schools";

const theme = createTheme({
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      light: "#f9f19e",
      main: "#c09f5b",
      dark: "#96652b",
      contrastText: "#fff",
    },
    secondary: {
      light: "#55B9B9",
      main: "#15413c",
      dark: "#157D78",
      contrastText: "#000",
    },
    tertiary: "#deeafd",
    background: {
      paper: "rgba(255, 255, 255, 0.5)",
      paperSolid: "rgba(208, 207, 207, 1)",
    },
    text: {
      primaryDark: "#173A5E",
      primaryLight: "#cae3fe",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    },
    // success: {
    //   dark: "#009688",
    // },
  },
  background1: `linear-gradient(to right, ${grey[900]}, ${grey[800]})`,
});

const App = () => (
  <ThemeProvider theme={theme}>
    <div className="App">
      <Navbar />
      <Header />
      <Routes style={{ padding: 40 }}>
        <Route exact path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/board" element={<Board />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/sponsors" element={<Sponsors />} />
        <Route path="/schools" element={<Schools />} />
      </Routes>
      <Copyright sx={{ mt: 5 }} />
    </div>
  </ThemeProvider>
);

export default App;
