import Container from "@mui/material/Container";
import Section from "components/Section";
import Profile from "components/Profile";
import data from "./data";

import styles from "./styles";

const Board = () => (
  <Container maxWidth="xl">
    <Section style={styles.root} title="Board Members:">
      {data.map((profile, i) => (
        <Profile key={i} {...profile} />
      ))}
    </Section>
  </Container>
);

export default Board;
